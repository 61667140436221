import React, {useState} from "react"

import styles from './component.module.less'

export default ({ closeButtonLabel, children }) => {
  const [active, setActive] = useState();

  return (
    <div className={`${styles.stickyNotice}${active ? ` ${styles.act}` : ''}`}>
      {active && (
          <div className={styles.content}>
            { children }
          </div>
      )}
      <button type="button" onClick={() => setActive(!active)}>
        {active && <span>{closeButtonLabel}</span>}
      </button>
    </div>
  )
}
