import React from "react"
import {graphql, StaticQuery} from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import ContentContainer from '../content-container'

import Microdata from './components/microdata'
import StickyNotice from './components/sticky-notice'

import styles from './component.module.less'

export default props => {

  const locale = props.locale || 'de';

  const isMinimal = props.minimal === true;

  const showSocialMedia = props.showSocialMedia === undefined ? true : props.showSocialMedia === true;

  const footerNavRenderer = (items) => (
      <>
        {(items || []).map((f, i) => {
          const isExternal = f.url && (f.url.startsWith('http:') || f.url.startsWith('https:'));

          const isCookieConsent = f.url && f.url === '?cc-settings';
          if (isCookieConsent) {
            return (
                <button
                    key={i}
                    onClick={() => {
                      if (!props.cookieConsentRef || !props.cookieConsentRef.current) return;
                      props.cookieConsentRef.current.setVisible();
                    }}
                >{f.title}</button>
            );
          }

          const url = f.url ? f.url : '/' + locale + '/' + f.link.slug + '/';

          if (isExternal) {
            return (<a key={i} target="_blank" rel="noreferrer" href={url}>{f.title}</a>);
          }

          return (
            <a key={i} href={url}>{f.title}</a>
          )
        })}
      </>
  );

  const extraNavRenderer = (title, items) => {
    if (!title && (!items || items.length === 0)) {
      return null;
    }

    return (
        <div className={styles.col}>
          {title && <div>{title}</div>}

          {items.length > 0 &&
            <nav>
              {items.map((item, i) => {
                let url = '/' + locale + '/';
                if (item.urlExternal) {
                  return (<a key={i} href={item.urlExternal} target="_blank" rel="noreferrer">{item.title}</a>);
                } else if (item.link && item.link.slug) {
                  url = '/' + locale + '/' + item.link.slug + '/';
                }
                return (<a key={i} href={url}>{item.title}</a>);
              })}
            </nav>
          }
        </div>
    );
  }

  return (
      <StaticQuery
          query={graphql`
            query {
                allSite {
                    nodes {
                        buildTime
                    }
                }

                allDatoCmsNavigationFooterExtra {
                    nodes {
                        locale
                        area1Title
                        area1 {...nav_footer_extra_item}
                        area2Title
                        area2 {...nav_footer_extra_item}
                    }
                }

                allDatoCmsNavigationFooter(sort: {fields: position, order: ASC}) {
                    nodes {
                        ...nav_footer
                    }
                }

                allDatoCmsConfig {
                    nodes {
                        locale
                        companyName
                        companyAddress
                        companyAddressZip
                        companyAddressCity
                        companyFax
                        footerStickyNoticeActive
                        footerSeals {
                            url
                            image {
                                alt
                                gatsbyImageData(
                                    layout: CONSTRAINED
                                    forceBlurhash: true
                                    imgixParams: {w: "132", auto: "compress", dpr: 1.5, q: 75}
                                )
                            }
                        }
                        socialMediaChannels {
                            url
                            kind
                        }
                    }
                }
                
                allDatoCmsTemplateTexte {
                    nodes {
                        locale
                        copyrightAllRightsReserved
                        stickyNoticeText
                        stickyNoticeButtonLabel
                    }
                }
            }
          `}
          render={data => {
            const buildTime = new Date(Date.parse(data.allSite.nodes[0].buildTime));

            const config = data.allDatoCmsConfig.nodes.find(n => n.locale === locale) || {};

            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

            const showStickyNote = !(props.hideStickyNote === true) && (config.footerStickyNoticeActive === true);

            const footerNav = data.allDatoCmsNavigationFooter.nodes.filter(n => {
              if (n.locale !== locale) return false;
              if (!n.title) return false;
              if (!n.url && (!n.link || !n.link.slug)) return false;
              if (isMinimal && !n.minimalEnabled) return false;
              return true;
            });

            const footerNavColCount = Math.round(footerNav.length / 2) + (footerNav.length > 0 && footerNav.length % 2 === 0 ? 1 : 0);

            const footerNav1 = footerNav.slice(0, footerNavColCount);
            const footerNav2 = footerNav.slice(footerNavColCount);

            const extraNav = data.allDatoCmsNavigationFooterExtra.nodes.find(n => n.locale === locale) || {};

            return (
                <>
                  <footer className={styles.footer} data-datocms-noindex={true}>
                    <div className={styles.upper}>
                      <ContentContainer className={styles.container}>

                        {!isMinimal && extraNavRenderer(extraNav.area1Title, extraNav.area1)}

                        {!isMinimal && extraNavRenderer(extraNav.area2Title, extraNav.area2)}

                        {config.footerSeals &&
                          <div className={`${styles.col} ${styles.seals}`}>
                            {config.footerSeals.map((s, i) => {
                              if (s.url) {
                                const isExternal = s.url.startsWith('http:') || s.url.startsWith('https:');
                                return (
                                    <a key={i} href={s.url} target={isExternal ? '_blank' : null} rel={isExternal ? 'noreferrer' : null}>
                                      <GatsbyImage
                                          className={styles.imgCnt}
                                          as="span"
                                          image={remoteAssetUrl(s.image.gatsbyImageData)}
                                          alt={s.image.alt}
                                      />
                                    </a>
                                );
                              }

                              return (
                                  <GatsbyImage
                                      key={i}
                                      className={styles.imgCnt}
                                      as="span"
                                      image={remoteAssetUrl(s.image.gatsbyImageData)}
                                      alt={s.image.alt}
                                  />
                              );
                            })}
                          </div>
                        }
                      </ContentContainer>
                    </div>

                    <div className={styles.lower}>
                      <ContentContainer className={styles.container}>

                        <nav className={styles.col}>
                          {showSocialMedia && config.socialMediaChannels.length > 0 &&
                              <div className={styles.sm}>
                                {config.socialMediaChannels.map((chan, i) => {
                                    let kindClassName;
                                    switch (chan.kind.trim()) {
                                      case 'YouTube':
                                        kindClassName = styles[chan.kind.trim().toLowerCase()];
                                      case 'Facebook':
                                      case 'Twitter':
                                      case 'Instagram':
                                      case 'Linkedin':
                                        kindClassName = styles[chan.kind.trim().toLowerCase()];
                                        break;
                                      default:
                                        break;
                                    }
                                    return (<a
                                        key={i}
                                        className={kindClassName}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={chan.url}
                                        data-icon={'url(/' + kindClassName + ')'}
                                    >{chan.kind}</a>);
                                })}
                              </div>
                          }

                          {footerNavRenderer(footerNav1)}
                        </nav>

                        <div className={`${styles.col} ${styles.logo}`} />

                        <nav className={styles.col}>
                          {footerNavRenderer(footerNav2)}
                        </nav>
                      </ContentContainer>

                      <div className={styles.copyright}>
                        Copyright &copy; {buildTime.getFullYear()} {config.companyName} – {templateStrings.copyrightAllRightsReserved}.
                      </div>
                    </div>
                  </footer>

                  {showStickyNote &&
                    <StickyNotice
                        closeButtonLabel={templateStrings.stickyNoticeButtonLabel}
                    >
                      <div dangerouslySetInnerHTML={{ __html: templateStrings.stickyNoticeText }} />
                    </StickyNotice>
                  }

                  <Microdata locale={locale} config={config} />
                </>
            );
          }}
      />
  );
}
