const searchPath = locale => {
  switch (locale) {
    case 'de':
      return '/de/suchergebnis/'
    case 'en':
      return '/en/searchresult/'
    case 'hu':
      return '/hu/searchresult/'
    case 'pl':
      return '/pl/searchresult/'
    case 'sk':
      return '/sk/searchresult/'
    case 'lt':
      return '/lt/searchresult/'
    default:
      throw new Error('locale ' + locale + ' not supported');
  }
}

const vpSearchPath = locale => {
  switch (locale) {
    case 'de':
      return '/de/vp-suchergebnis/'
    default:
      throw new Error('locale ' + locale + ' not supported');
  }
}

const vpSearchParamName = 'vs';

export { searchPath, vpSearchPath, vpSearchParamName }
