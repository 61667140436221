import React from "react"

import { searchPath } from '../../../../lib/search-path'

import Button from '../../../button'

import styles from './component.module.less'

class SearchOverlay extends React.Component {

  locale;

  constructor(props) {
    super(props);

    this.locale = props.locale || 'de';

    this.state = {
      visible: false,
      close: false,
    };
  }

  setVisible() {
    this.setState({
      close: false,
      visible: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.close === true) {
      setTimeout(() => {
        this.setState({
          close: false,
          visible: false,
        });
      }, 150); // timeout based on css animation duration (example: @anim-duration-short)
    }
  }

  render() {
    if (process.env.GATSBY_FEATURE_SITESEARCH !== '1') {
      return null;
    }

    return (
        <>
          {this.state.visible &&
            <div className={`${styles.searchOverlay}${this.state.close ? ' ' + styles.closing : ''}`} data-datocms-noindex={true}>
              <div className={styles.container}>

                <Button className={styles.close} theme="block" onClick={() => this.setState({close: true})}>X</Button>

                <form action={searchPath(this.locale)}>

                  {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
                  <input type="search" name="s" placeholder={this.props.inputPlaceholder} autoFocus={true} />

                  <Button className={styles.submit} type="submit" theme="block">
                    {this.props.searchButtonLabel}
                  </Button>

                </form>
              </div>
            </div>
          }
        </>
    );
  }
}

export default SearchOverlay
