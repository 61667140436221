export default (imgObj, urlCallback) => {
  if (!urlCallback) {
    return imgObj;
  }

  if (!imgObj.images || !imgObj.images.fallback) {
    return imgObj;
  }

  if (imgObj.images.fallback.src) {
    imgObj.images.fallback.src = urlCallback(imgObj.images.fallback.src);
  }

  if (imgObj.images.fallback.srcSet) {
    imgObj.images.fallback.srcSet =
        imgObj.images.fallback.srcSet
            .split(',\n')
            .map(set => {
              const p = set.split(' ');
              if (p.length !== 2) {
                return set;
              }
              p[0] = urlCallback(p[0]);
              return p.join(' ');
            })
            .join(',\n');
  }

  return imgObj;
}
