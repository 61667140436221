import React, {useRef, useState} from "react"
import {graphql, StaticQuery} from "gatsby";

import { vpSearchPath, vpSearchParamName } from "../../../../lib/search-path"

import Button from "../../../button";

import styles from "./component.module.less";
import formStyles from "../../../form/component.module.less"

export default props => {

  if (process.env.GATSBY_FEATURE_VPSEARCH !== "1") {
    return null;
  }

  if (props.disabled === true) {
    return null;
  }

  const locale = props.locale || 'de';

  try {
    vpSearchPath(locale);
  } catch (e) {
    return null;
  }

  const mobActive = props.mobActive === true;

  const [show, setShow] = useState();
  const overlayRef = useRef();

  const toggle = () => {
    setShow(!show);

    if (!mobActive) return;

    window.setTimeout(() => {
      if (!overlayRef || !overlayRef.current) return;

      overlayRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 50);
  };

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          vpSearchMenuLabel
                          vpSearchOverlayTitle
                          vpSearchInputPlaceholder
                          vpSearchSubmitLabel
                      }
                  }
              }
          `}
          render={data => {
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

            const classNames = [styles.vpSearch];
            if (mobActive) {
              classNames.push(styles.mobActive);
            }
            if (show) {
              classNames.push(styles.show);
            }
            if (props.className) {
              classNames.push(props.className);
            }

            return(
              <div className={classNames.join(' ')}>
                <div className={styles.inner}>
                  <Button
                      className={`${styles.trigger}${props.btnClassName ? ' ' + props.btnClassName : ''}`}
                      onClick={toggle}
                  >
                    { templateStrings.vpSearchMenuLabel }
                  </Button>
                  { show &&
                    <form className={styles.overlay} action={vpSearchPath(locale)} ref={overlayRef}>
                      <label>
                        { templateStrings.vpSearchOverlayTitle }
                        {/* eslint-disable jsx-a11y/no-autofocus */}
                        <input
                            className={formStyles.formField}
                            type="text"
                            placeholder={templateStrings.vpSearchInputPlaceholder}
                            name={vpSearchParamName}
                            autoFocus={true}
                        />
                        {/* eslint-enable jsx-a11y/no-autofocus */}
                      </label>
                      <Button type="submit" theme="block" icon="search-light">
                        { templateStrings.vpSearchSubmitLabel }
                      </Button>
                    </form>
                  }
                </div>
              </div>
            )
          }}
      />
  )
}
