import React from "react"
import styles from "./component.module.less";

import Button from "../../../button";

import LangNav from "../lang-nav";
import VpSearch from "../vp-search";

export default (props) => (
    <div className={`${styles.tool}${props.mobActive === true ? ' ' + styles.mobActive : ''}${props.className ? ' ' + props.className : ''}`} data-datocms-noindex={true}>

      {!props.headerToolLinkText && !props.headerToolLinkUrl && (
        <VpSearch
          locale={props.locale}
          className={`${styles.item} ${styles.standalone}`}
          disabled={props.mobActive === true}
        />
      )}

      {props.headerToolLinkText && props.headerToolLinkUrl && (
        <div className={`${styles.item} ${styles.standalone}`}>
          <Button href={props.headerToolLinkUrl} target="_blank" rel="noreferrer">
            {props.headerToolLinkText}
          </Button>
        </div>
      )}

      <Button className={`${styles.item} ${styles.user}`} href="https://www.goldengates.digital/" target="_blank" rel="noreferrer">
        {props.myGoldenGatesLabel}
      </Button>

      {process.env.GATSBY_FEATURE_SITESEARCH === '1' && (
            <Button className={`${styles.item} ${styles.search}`} onClick={() => props.searchRef.current.setVisible()}>
              {props.searchLabel}
            </Button>
      )}

      <LangNav
          locale={props.locale}
          localeLinks={props.localeLinks}
          className={styles.lang}
      />
    </div>
)
