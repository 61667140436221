import React, {useState} from "react"

import Button from "../../../button";

import styles from './component.module.less'

export default (props) => {

  const locale = props.locale || 'de';
  const localeLinks = (props.localeLinks || []).filter(ll => ll.locale !== locale);
  if (localeLinks.length === 0) {
    return null;
  }

  const [showList, setShowList] = useState(false);

  return (
      <div className={`${styles.langNav}${props.className ? ' ' + props.className : ''}`}>
        <Button className={styles.btn} onClick={() => setShowList(!showList)}>
          {locale.toUpperCase()}
        </Button>

        <ul className={`${styles.list}${showList ? ' ' + styles.act : ''}`} style={{
          height: (localeLinks.length * 100) + '%',
        }}>
          {localeLinks.map((link, i) => (
              <li key={i}><a href={link.url}>{link.locale}</a></li>
          ))}
        </ul>
      </div>
  );
}
