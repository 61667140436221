import React, {useState} from "react"
import {graphql, StaticQuery} from "gatsby"
import VisibilitySensor from "react-visibility-sensor"

import ContentContainer from '../content-container'
import Button from "../button";

import SearchOverlay from './components/search-overlay'
import MainNav from './components/main-nav'
import ToolNav from './components/tool-nav'

import styles from './component.module.less'

export default function(props) {
  const locale = props.locale || 'de';

  const searchRef = React.createRef();

  const [headerVisible, setHeaderVisible] = useState(true);
  const [mobActive, setMobActive] = useState(false);

  const isMinimal = props.minimal === true;

  let classNames = [styles.header];

  if (props.transparent && props.transparent === true && headerVisible) {
    classNames.push(styles.transparent);
  }

  if (React.Children.count(props.children) > 0) {
    classNames.push(styles.withContent);
  }

  if (props.sticky === undefined || props.sticky === true) {
    if (!headerVisible) {
      classNames.push(styles.sticky);
    }
  }

  if (isMinimal) {
    classNames.push(styles.min);
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsConfig {
                    nodes {
                      locale
                      headerToolLinkText
                      headerToolLinkUrl
                    }
                  }
                  allDatoCmsTemplateTexte {
                      nodes {
                          locale
                          headerSearchLabel
                          headerMyGoldenGatesLabel
                          menBackButtonLabel
                          searchOverlayInputPlaceholder
                          searchOverlayButtonLabel
                      }
                  }
              }
          `}
          render={data => {
            const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);
            const config = data.allDatoCmsConfig.nodes.find(t => t.locale === (props.locale || 'de'));
            return (
                <>
                  {!isMinimal &&
                      <SearchOverlay
                          ref={searchRef}
                          locale={locale}
                          inputPlaceholder={templateStrings.searchOverlayInputPlaceholder}
                          searchButtonLabel={templateStrings.searchOverlayButtonLabel}
                      />
                  }

                  <VisibilitySensor
                      active={props.sticky === undefined || (props.sticky && props.sticky === true)}
                      resizeCheck={false}
                      partialVisibility={true}
                      onChange={isVisible => setHeaderVisible(isVisible)}
                  >
                    <div className={styles.headerBlock}>
                      <header className={`${classNames.join(' ')}${mobActive ? ' ' + styles.mobActive : ''}`}>
                        <div className={styles.navContainer}>
                          <ContentContainer className={styles.inner}>
                            <nav className={styles.nav}>

                              {!isMinimal &&
                                  <>
                                    <Button
                                        className={`${styles.toggle}${mobActive ? ' ' + styles.open : ''}`}
                                        onClick={() => setMobActive(!mobActive)}
                                    >
                                      Menu
                                    </Button>

                                    <MainNav
                                        locale={locale}
                                        mobActive={mobActive}
                                        setMobActive={setMobActive}
                                        backButtonLabel={templateStrings.menBackButtonLabel}
                                        className={styles.col}
                                    />
                                  </>
                              }

                              <div className={`${styles.col} ${styles.logo}`} data-datocms-noindex={true}>
                                <a href={'/' + locale + '/'}>Golden Gates</a>
                              </div>

                              {!isMinimal &&
                                  <ToolNav
                                      locale={locale}
                                      localeLinks={props.localeLinks}
                                      className={styles.col}
                                      mobActive={mobActive}
                                      searchRef={searchRef}
                                      myGoldenGatesLabel={templateStrings.headerMyGoldenGatesLabel}
                                      searchLabel={templateStrings.headerSearchLabel}
                                      headerToolLinkText={config.headerToolLinkText}
                                      headerToolLinkUrl={config.headerToolLinkUrl}
                                  />
                              }

                            </nav>
                          </ContentContainer>
                        </div>
                      </header>
                      <div className={styles.content}>
                        {props.children}
                      </div>
                    </div>
                  </VisibilitySensor>
                </>
            );
          }}
      />
  );
}
